<template>
  <div>
    <button
      class="btn btn-primary mr-1"
      data-testid="toolbar-related"
      @click="togglePanel('related')"
    >
      <ArchiveIcon aria-hidden class="feather-sm mr-1"></ArchiveIcon>
      Related Items
    </button>
    <button
      class="btn btn-primary mr-1"
      title="Add selection to a group"
      @click="togglePanel('link')"
    >
      <LinkIcon
        aria-hidden
        class="feather-sm mr-1"
        data-testid="toolbar-link"
      ></LinkIcon>
      Link
    </button>
    <button
      class="btn btn-primary mr-1"
      title="Remove items from a group"
      data-testid="toolbar-unlink"
      @click="togglePanel('unlink')"
    >
      <UnlinkIcon aria-hidden class="feather-sm mr-1"></UnlinkIcon>
      Unlink
    </button>
  </div>
</template>

<script>
import LinkIcon from "../assets/link.svg"
import UnlinkIcon from "../assets/unlink.svg"
import ArchiveIcon from "../assets/archive.svg"
export default {
  components: { LinkIcon, UnlinkIcon, ArchiveIcon },
  methods: {
    togglePanel(name) {
      const { gridApi } = this.$store.state
      if (gridApi.getOpenedToolPanel() === name) {
        gridApi.closeToolPanel()
      } else {
        gridApi.openToolPanel(name)
      }
    },
  },
}
</script>

<style></style>
